<script setup lang="ts">
const props = defineProps<{
  logo: string
}>()

const logo = ref('')

async function fetchLogo() {
  try {
    const importLogo = import.meta.glob('assets/logos/**/**.svg', { query: '?raw', import: 'default', eager: false })
    logo.value = await importLogo[`/assets/logos/${props.logo}.svg`]() as string
  }
  catch {
    console.error(`Logo ${props.logo} not found in assets/logos`)
  }
}

await fetchLogo()

watchEffect(fetchLogo)
</script>

<template>
  <span
    class="mb[0.125em] [&>svg]:h[1em] [&>svg]:wauto [&>svg]:align-middle"
    v-html="logo"
  />
</template>
